.privacyPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.privacyTitle {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
}

.titleOne {
    font-family: 'Montserrat';
    font-size: 2em;
    font-weight: 500;
}

.updateDate {
    font-family: 'Montserrat';
    font-size: 1.5em;
}

.introTitle{
    font-size: 1.5em;
    font-family: 'Montserrat';
}

.introPara {
    font-family: 'Montserrat';
    width: 750px;
}

.miniTitle {
    font-family: 'Montserrat';
    font-weight: 700;
}

@media all and (max-width: 430px) {
    .privacyPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .privacyTitle {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    .titleOne {
        font-family: 'Montserrat';
        font-size: 2em;
        font-weight: 500;
        width: 300px;
    }
    
    .updateDate {
        font-family: 'Montserrat';
        font-size: 1.5em;
    }
    
    .introTitle{
        font-size: 1.5em;
        font-family: 'Montserrat';
        width: 300px;
    }
    
    .introPara {
        font-family: 'Montserrat';
        width: 350px;
    }
    
    .miniTitle {
        font-family: 'Montserrat';
        font-weight: 700;
        width: 300px;
    }
}