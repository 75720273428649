
.servicePage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.dsLine {
    font-size: 4em;
    font-family: 'rubik';
    font-weight: 100;
    margin-left: 2em;
    
}

.ds2 {
    font-weight: 700;
}

.dsPara {
    width: 650px;
    font-family: 'rubik';
    margin-left: 8em;
    margin-top: -3em;
    margin-bottom: 5em
}


.OfficeTeam {
    width: 100%;
    height: auto;
}
.serviceList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
    gap: 5em;
    margin-top: 5em;
}

.supercharger {
    font-size: 4em;
    font-family: 'rubik';
    font-weight: 100;
    margin-left: 0em;
}

.buttonBoxS {
    margin-top: 0em;
    margin-left: -20em;
}

.startButton {
    width: 200px;
    height: 70px;
    background-color: orangered;
    border: none;
    outline: none;
    color: white;
    font-family: 'rubik';
    font-size: 1em;
    margin-top: -3em;
    
}

.arrowService {
    margin-top: 0.7em;
    font-size: 1.5em;
    margin-right: -0.5em;
}

.Practice {
    display: flex;
    gap: 1em;
    font-family: 'Rubik';
    margin-top: 1.5em;
}

.serviceOne {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
    border-top: 2px solid orangered;
    width: 350px;
    padding: 15px 15px;
    background-color: #FFF7FC;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    
}


.webMobile {
    font-size: 1.3em;
    font-family: 'rubik';
    font-weight: 500;

}

.servicePara {
    width: 350px;
    font-family: 'Rubik';
}

.toolsBar {
    display: flex;
    gap: 2em;
}


.industries {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.insRow1 {
    display: flex;
    gap: 5em;
    margin-bottom: 3em;
}

.retailBox {
    width: 250px;
    height: 170px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.titleIns {
    font-family: 'Rubik';
    font-weight: 100;
    font-size: 1.5em;
    margin-left: 1em;
}

.insTitle {
    font-family: 'Rubik';
    font-weight: 700;
    font-size: 3em;
    margin-top: 2em;
    border-bottom: 3px solid orangered;
    width: 450px;
}

.Bank {
    width: 50px;
    margin-top: 1em;
    margin-left: 2em;
}

@media all and (max-width: 430px) {
    
.servicePage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.dsLine {
    font-size: 2em;
    font-family: 'rubik';
    font-weight: 100;
    margin-left: 0.5em;
    
}

.ds2 {
    font-weight: 700;
}

.dsPara {
    width: 300px;
    font-family: 'rubik';
    margin-left: 1em;
    margin-top: -0em;
    margin-bottom: 5em
}


.OfficeTeam {
    width: 100%;
    height: auto;
}
.serviceList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: left;
    gap: 5em;
    margin-top: 5em;
}

.supercharger {
    font-size: 2em;
    font-family: 'rubik';
    font-weight: 100;
    margin-left: 0em;
}

.buttonBoxS {
    margin-top: 0em;
    margin-left: 1em;
}

.startButton {
    width: 200px;
    height: 70px;
    background-color: orangered;
    border: none;
    outline: none;
    color: white;
    font-family: 'rubik';
    font-size: 1em;
    margin-top: -3em;
    
}

.arrowService {
    display: none;
    margin-top: 0.7em;
    font-size: 1em;
    margin-right: -0.5em;
}

.Practice {
    display: flex;
    flex-direction: column;
    gap: 2em;
    font-family: 'Rubik';
    margin-top: 1.5em;
}

.serviceOne {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    align-items: left;
    border-top: 2px solid orangered;
    width: 350px;
    padding: 15px 15px;
    background-color: #FFF7FC;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    
}


.webMobile {
    font-size: 1.3em;
    font-family: 'rubik';
    font-weight: 500;

}

.servicePara {
    width: 350px;
    font-family: 'Rubik';
}

.toolsBar {
    display: flex;
    gap: 2em;
}


.industries {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.insRow1 {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 3em;
}

.retailBox {
    display: flex;
    width: 270px;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.titleIns {
    font-family: 'Rubik';
    font-weight: 100;
    font-size: 1.5em;
    margin-left: 1em;
}

.insTitle {
    font-family: 'Rubik';
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    border-bottom: 3px solid orangered;
    width: 250px;
}

.Bank {
    width: 30px;
    height: 30px;
    margin-top: 1.5em;
    margin-left: 2em;

}


}