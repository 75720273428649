

.contactPage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5em;
}


.companyBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-self: center;
    margin-top: -5em;
    
}

.companyName {
    font-family: 'Montserrat';
    font-size: 2.5em;
    text-align: center;
    font-weight: 700;
}

.dot1 {
    color: orangered;
    margin-left: -0.4em;
}

.dsc {
    font-family: 'Rubik';
    font-weight: 100;
}

.companyPara {
    font-family: 'Rubik';
    width: 650px;
    text-align: justify;
}

.contactBox {
    display: flex;
    flex-direction: column;
    align-items: LEFT;
    justify-content: center;
    gap: 1em;
    width: 450px;
    border-radius: 15px 15px ;
}

.contactTitle {
    font-family: 'Rubik';
    font-size: 3em;
    color: black;
    font-weight: 600;
}

.nameInput {
    display: flex;
    gap: 1em;
}

.firstName {
    width: 250px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1.5em;
    font-family: 'Rubik';
    background-color: none;
}



.emailInput {
    width: 520px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1.5em;
    font-family: 'Rubik';
    background-color: none;
}

.consentBox {
    display: flex;
    gap: 2em;
}

.consent {
    font-family: 'Rubik';
}

.need {
    font-family: 'Rubik';
}

.sendBox {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.sendButton {
    border-radius: 25px 25px;
    width: 150px;
    height: 50px;
    font-family: 'Rubik';
    font-size: 1em;
    border: none;
    color: white;
    background-color: black;
}

textarea {
    width: 520px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1.5em;
    font-family: 'Rubik';
    background-color: none;
}

.privacy {
    color: #5356FF;
    font-family: 'Rubik';
    font-weight: 600;
    text-decoration: underline;
    margin-left: -2.5em;
    margin-top: -0em;
}

.directCall {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: flex-start;
}

.contactHead {
    font-family: 'Rubik';
    font-size: 2.5em;
}

.contactLineTwo {
    font-family: 'Rubik';
    margin-top: -2em;
}

.details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
}
.detailsOne {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 1.5em;
}

.infoDetails {
    display: flex;
    gap: 3em;
}

.mobileNumber {
    font-family: 'Rubik';
    margin-top: 0em;
    cursor: pointer;
}

.emailContact {
    font-family: 'Rubik';
    margin-top: 0em;
    cursor: pointer;
}

@media all and (max-width: 430px) {

    

.contactPage {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0em;
}


.companyBox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-self: center;
    margin-top: 0em;
    

    
}

.companyName {
    font-family: 'Rubik';
    font-size: 1.3em;
    text-align: center;
    font-weight: 700;
}

.companyPara {
    font-family: 'Rubik';
    width: 250px;
    text-align: justify;
}

.contactBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0em;
    border: 1px solid black;
    width: 300px;
    padding: 15px 15px;
}

.contactTitle {
    font-family: 'Rubik';
    font-size: 1em;
    color: black;
    font-weight: 600;
}

.nameInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.secondInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.firstName {
    width: 250px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1em;
    font-family: 'Rubik';
    background-color: none;
}



.emailInput {
    width: 250px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1em;
    font-family: 'Rubik';
    background-color: none;
}

.consentBox {
    display: flex;
    gap: 2em;
}

.consent {
    font-family: 'Rubik';
    width: 250px;
}

.need {
    font-family: 'Rubik';
}

.sendBox {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.further {
    width: 250px;
}
.sendButton {
    border-radius: 25px 25px;
    width: 150px;
    height: 50px;
    font-family: 'Rubik';
    font-size: 1em;
    border: none;
    color: white;
    background-color: black;
}

textarea {
    width: 250px;
    height: 70px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    color: black;
    font-size: 1em;
    font-family: 'Rubik';
    background-color: none;
}

.privacy {
    color: #5356FF;
    font-family: 'Rubik';
    font-weight: 600;
    text-decoration: underline;
    margin-left: em;
    margin-top: -0em;
}

.directCall {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.contactHead {
    font-family: 'Rubik';
    font-size: 1.5em;
}

.contactLineTwo {
    font-family: 'Rubik';
    margin-top: 0em;
    width: 250px;
}

.details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
}
.detailsOne {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 1.5em;
}

.infoDetails {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-bottom: 3em;
}

.mobileNumber {
    font-family: 'Rubik';
    margin-top: 0em;
    cursor: pointer;
}

.emailContact {
    font-family: 'Rubik';
    margin-top: 0em;
    cursor: pointer;
}

}