

.workPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.workOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3em;
}

.workLine1 {
    font-family: 'Rubik';
    font-size: 2.5em;
    font-weight: 700;
    width: 850px;
    margin-left: -5em;
    margin-top: 2em;
}
.workList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2em;
    margin-left: -35em;
}

.workListOne {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.workNumber {
    font-family: 'Rubik';
    font-size: 2em;
    font-weight: 700;
}

.workNumber2 {
    font-family: 'Rubik';
    font-size: 1em;
    font-weight: 500;
    margin-top: -1em;
}

.conceptRow1 {
    display: flex;
    align-items: center;
    gap: 2.5em;
}

.firstC {
    display: flex;
    flex-direction: column;
}

.fox {
    width: 600px;
}
.lineDraw {
    margin-top: 1em;
}

.domain {
    font-family: 'Rubik';
    font-weight: 200;
    font-size: 2em;
    margin-top: 0em;
}

.insurance {
    width: 600px;
    margin-top: 2.3em;
}

.importantLine {
    display: flex;
    align-items: center;
    gap: 2.5em;
}

.focusLine {
    font-family: 'Rubik';
    font-size: 1.5em;
    font-weight: 700;
}

.callButton22 {
    width: 150px;
    height: 50px;
    background-color: orangered;
    border: none;
    outline: none;
    color: white;
    font-family: 'Rubik';
}
.statement {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.Standard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.strong {
    font-family: 'Rubik';
    font-size: 2.5em;
    font-weight: 700;
}

.statementMini {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5em;
}

.star1 {
    width: 50px;
}

.stdClass {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-bottom: -2em;
}

.stdClass2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-bottom: -2em;
}

.stdTitle {
    font-size: 2em;
    font-family: 'Rubik';
}


.stdLine {
    font-family: 'Rubik';
    width: 370px;
}

.stdLine2 {
    font-family: 'Rubik';
    width: 350px;
}

@media all and (max-width: 430px) {
    

.workPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.workOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3em;
}

.workLine1 {
    font-family: 'Rubik';
    font-size: 1.5em;
    font-weight: 700;
    width: 350px;
    margin-left: 0em;
    margin-top: 2em;
}
.workList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    margin-left: 0em;
}

.workListOne {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workNumber {
    font-family: 'Rubik';
    font-size: 2em;
    font-weight: 700;
}

.workNumber2 {
    font-family: 'Rubik';
    font-size: 1em;
    font-weight: 500;
    margin-top: -1em;
}

.conceptRow1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5em;
}

.firstC {
    display: flex;
    flex-direction: column;
    flex-direction: column;
}

.fox {
    width: 300px;
}
.lineDraw {
    margin-top: 1em;
}

.domain {
    font-family: 'Rubik';
    font-weight: 200;
    font-size: 1em;
    margin-top: 0em;
}

.insurance {
    width: 300px;
    margin-top: 2.3em;
}

.importantLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5em;
}

.focusLine {
    font-family: 'Rubik';
    font-size: 1em;
    font-weight: 700;
}

.callButton22 {
    width: 150px;
    height: 50px;
    background-color: orangered;
    border: none;
    outline: none;
    color: white;
    font-family: 'Rubik';
}

.statement {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Standard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.strong {
    font-family: 'Rubik';
    font-size: 1.5em;
    font-weight: 700;
}

.statementMini {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4em;
}

.star1 {
    width: 50px;
}

.stdClass {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7em;
    margin-bottom: -2em;
}

.stdClass2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5em;
    margin-bottom: -2em;
}

.stdTitle {
    font-size: 2em;
    font-family: 'Rubik';
}


.stdLine {
    font-family: 'Rubik';
    width: 300px;
}

.stdLine2 {
    font-family: 'Rubik';
    width: 300px;
}
}