
.footerPage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 450px;
    background-color: black;
    gap: 35em;
    margin-top: 3em;
}

.companyDetails {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.leftBar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    margin-top: -15em;
}

.touch {
    font-size: 5em;
    color: white;
    font-weight: 700;
    font-family: 'Rubik';
}

.growthLine {
    font-size: 3em;
    color: white;
    font-weight: 300;
    font-family: 'Rubik';
    margin-top: -2em;
}

.logoW {
    width: 250px;
}

.socialMedia {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 3em;
    margin-top: 5em;
}

a {
    text-decoration: none;
    color: white;
}

.socialName {
    font-size: 1.5em;
}

.socialName:hover {
    color: orangered;
    transition: 1.5s;
}

.addLine {
    color: white;
    font-size: 1.5em;
    font-weight: 200;
    margin-left: 8em;
}

.clutch {
    font-family: 'Montserrat';
    margin-left: 14em;
}

@media all and (max-width: 430px) { 

    .footerPage {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;
        width: 100%;
        height: 750px;
        background-color: black;
        gap: 15em;
        margin-top: 1em;
    }
    
    .companyDetails {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    .leftBar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: left;
        justify-content: left;
        margin-top: -5em;
    }
    
    .touch {
        font-size: 3em;
        color: white;
        font-weight: 700;
        font-family: 'Montserrat';
    }
    
    .growthLine {
        font-size: 2em;
        color: white;
        font-weight: 300;
        font-family: 'Montserrat';
        margin-top: -1.5em;
    }
    
    .logoW {
        width: 250px;
    }
    
    .socialMedia {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 3em;
        margin-top: 1em;
    }
    
    a {
        text-decoration: none;
        color: white;
    }
    
    .socialName {
        font-size: 1.5em;
    }
    
    .socialName:hover {
        color: orangered;
        transition: 1.5s;
    }
    
    .addLine {
        color: white;
        font-size: 1.5em;
        font-weight: 200;
    }
    .clutch {
        font-family: 'Montserrat';
        margin-left: 0em;
    }
    

}