

.homePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'montserrat';
   
}

.firstPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    
}


.leftSide1 {
display: flex;
flex-direction: column;
align-items: left;
justify-content: left;
margin-top: 7em;
}

.stayAhead {
    font-size: 5em;
    font-weight: 700;
    margin-top: -1em;
    font-family: 'Rubik';
    width: 1000px;
}

.stay2 {
    color: orangered;
}


.line2 {
    width: 600px;
    font-size: 1.1em;
    margin-top: -1em;
    margin-bottom: 3em;
}

.targetSpan {
    font-weight: 700;
    color: orangered;
}
.buttons1 {
    display: flex;
    margin-top: -1em;
}

.call1 {
    background-color: white;
    color: black;
    font-family: 'montserrat';
    padding: 15px 15px;
    width: 250px;
    height: 50px;
    border-radius: 15px 15px;
    outline: none;
    font-size: 1em;
}

.arrow2 {
    margin-left: 5em;
    font-size: 1em;
    rotate: -45deg;
}

.vs1 {
    text-decoration: underline;
    margin-left: 5em;
    color: black;
}

.rightBox1 {
position: relative;
z-index: -1;
margin-bottom: -15em;

}

.mobilePic {
    width: 450px;
    margin-left: -15em;
}

.rrB1 {
    margin-left: 1em;
    font-size: 1.5em;
    margin-bottom: -0.5em;
   
}

.rrb2 {
    margin-left: 1em;
    font-size: 2em;
    font-weight: 700;
    margin-top: 8em;
}

.spanPlus {
    color: green;
}

.secondUpper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 850px;
    margin-top: 1em;
}


.serviceTitle1 {
    color: white;
    font-weight: 700;
    font-size: 2.5em;
    margin-top: -2em;
}

.growSpan {
    font-weight: 300;
}

.secondPage {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
}

.serviceBox11 {
    width: 300px;
    height: 350px;
    background-color: white;
    border-radius: 15px 15px;
}

.appDev1 {
    font-weight: 500;
    font-size: 1.2em;
    margin-left: 1em;
    font-weight: 700;
    font-family: 'rubik';
}

.spanLine2 {
    font-weight: 300;
}

.appDev12 {
    font-weight: 500;
    font-size: 1.5em;
    margin-left: 1.5em;
    font-weight: 700;
    font-family: 'Caveat';
}

.serviceNames {
    margin-left: 2em;
    margin-top: 3em;
}

.buttonDiv {
    width: 100px;
    height: 30px;
    border-radius: 15px 15px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    margin-left: 12em;
}

.startRocket {
    width: 100px;
   }
    
.buttonDiv1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0
    .5em;
    margin-left: 12em;
}

.rocket22 {
    width: 50px;
} 

.buttonDiv2 {
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0em;
    margin-left: 12em;
}

.rocket33 {
    width: 100px;
    margin-top: -5em;
}
 

.buttonDiv3 {
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    margin-left: 12em;
}

.rocket44 {
    width: 100px;
    margin-top: -2.5em;
}


.buttonDiv4 {
    
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4.2em;
    margin-left: 12em;
}


.whatGet {
    color: white;
    font-weight: 200;
    font-size: 2.5em;
}
.getWhat {
    font-weight: 700;
}

.iconClass {
    display: flex;
    align-items: left;
    justify-content: center;
}

.qualityLine {
    color: white;
    width: 350px;
    border-left: 1px solid orangered;
    padding: 5px 15px;
}

.secondRowBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
}

.thirdPage {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.thirdLower {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.thirdTitle {
    font-size: 3em;
    font-weight: 300;
}

.thirdRowOne {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.discover {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    border-top: 1px solid orangered;
    width: 300px;
    height: 300px;
}

.numbers {
    font-family: 'rubik';
    font-weight: 700;
    font-size: 3em;
    margin-top: -0em;
    color: #C7C8CC;
}

.discovery {
    font-size: 2em;
    font-weight: 700;
    margin-top: -1em;
    font-family: 'Rubik';
}

.disLine2 {
    width: 250px;
    margin-top: -1em;
}

.fourthLine {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.market {
    font-family: 'Rubik';
    font-size: 3em;
    border-left: 5px solid orangered;
    padding: 5px 5px;
}

.firstRow4 {
    display: flex;
    gap: 1em;
}

.TA1 {
    font-family: 'Rubik';
    font-size: 2em;
    border-left: 2px solid orangered;
    padding: 5px 5px;
    margin-bottom: -2em;
    margin-left: 1.5em;
} 

.lowInvest {
    width: 700px;
    margin-top: 14em;
}

.vcPe {
    font-weight: 500;
    margin-left: 21em;
    border-left: 2px solid orangered;
    padding: 5px 5px;
}

.genAI {
    font-family: 'rubik';
    font-weight: 700;
    font-size: 2em;
    width: 400px;
    margin-left: 9em;
    text-align: right;
    margin-top: -0em;
}

.readNow {
    color: #4C3BCF;
    text-decoration: none;
    font-weight: 500;
    margin-top: -1em;
    margin-left: 37.5em;

}

.genZ {
    width: 700px;
}

.firstRow5 {
    
}

.workingRow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    margin-top: 5em;
}

.working {
    width: 300px;
    
}

.workingTitle {
    font-family: 'Rubik';
    font-weight: 700;
    width: 250px;
}

.readNow2 {
    color: #4C3BCF;
    text-decoration: none;
    font-weight: 500;
}


@media all and (max-width: 430px) { 
   
    .homePage {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'montserrat';
       
    }
    
    .firstPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10em;
        gap: 2em;
        
    }
    
    
    .leftSide1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3.5em;
    }
    
    .stayAhead {
        font-size: 2.5em;
        font-weight: 700;
        margin-top: -0.5em;
        font-family: 'Rubik';
        margin-left: 0em;
        width: 350px;
    }
    .stay2 {
        color: orangered;
    }
    
    .line2 {
        width: 350px;
        font-size: 1em;
        margin-top: -1em;
    }
    
    .buttons1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
    }
    
    .call1 {
        background-color: white;
        color: black;
        font-family: 'Rubik';
        padding: 15px 15px;
        width: 250px;
        height: 50px;
        border-radius: 55px 55px;
        outline: none;
        font-size: 1em;
    }
    
    .arrow2 {
        margin-left: 5em;
        font-size: 1em;
        rotate: -45deg;
    }
    
    .vs1 {
        text-decoration: underline;
        margin-left: 0em;
    }
    
    .rightBox1 {
        position: relative;
        z-index: -1;
        margin-bottom: -9em;
        }
        
        .mobilePic {
            width: 380px;
            margin-left: 0em;
        }
        
    .rrB1 {
        margin-left: 1em;
        font-size: 1.5em;
        margin-bottom: -0.5em;
       
    }
    
    .rrb2 {
        margin-left: 1em;
        font-size: 2em;
        font-weight: 700;
        margin-top: 8em;
    }
    
    .spanPlus {
        color: green;
    }
    
    .secondUpper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        width: 100%;
        height: 2150px;
        margin-top: 0em;
    }
    
    
    .serviceTitle1 {
        color: white;
        font-weight: 700;
        font-size: 1.5em;
        margin-top: -2.5em;
        margin-left: 2em;
    }
    
    .growSpan {
        font-weight: 300;
    }
    
    .secondPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        margin-top: 1em;
    }
    
    .serviceBox11 {
        width: 300px;
        height: 350px;
        background-color: white;
        border-radius: 15px 15px;
    }
    
    .appDev1 {
        font-weight: 500;
        font-size: 1.2em;
        margin-left: 1em;
        font-weight: 700;
        font-family: 'rubik';
    }
    
    .spanLine2 {
        font-weight: 300;
    }
    
    .appDev12 {
        font-weight: 500;
        font-size: 1.5em;
        margin-left: 1.5em;
        font-weight: 700;
        font-family: 'Caveat';
    }
    
    .serviceNames {
        margin-left: 2em;
        margin-top: 3em;
    }
    
    .buttonDiv {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
        margin-left: 12em;
    }
    
   .startRocket {
    width: 100px;
   }


    
    .buttonDiv1 {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0em;
        margin-left: 12em;
    }
    
    .rocket22 {
        width: 50px;
    }
    .buttonDiv2 {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0em;
        margin-left: 12em;
    }

    .rocket33 {
        width: 100px;
        margin-top: -5em;
    }
    
    .buttonDiv3 {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3em;
        margin-left: 12em;
    }
    
    .rocket44 {
        width: 100px;
        margin-top: -3em;
    }
    

    .buttonDiv4 {
        
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4.2em;
        margin-left: 12em;
    }
    
    
    .whatGet {
        color: white;
        font-weight: 200;
        font-size: 2em;
        margin-top: 3em;
    }
    .getWhat {
        font-weight: 700;
    }
    
    .iconClass {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .qualityLine {
        color: white;
        width: 350px;
        border-left: 1px solid orangered;
        padding: 5px 15px;
    }
    
    .secondRowBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
    
    .thirdPage {
        display: flex;
       
        align-items: left;
        justify-content: center;
    }
    
    .thirdLower {
        display: flex;
        
        align-items: left;
        justify-content: left;
    }
    
    .thirdTitle {
        font-size: 1.5em;
        font-weight: 300;
    }
    
    .thirdRowOne {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
    
    .discover {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        border-top: 1px solid orangered;
        width: 300px;
        height: 300px;
    }
    
    .numbers {
        font-family: 'rubik';
        font-weight: 700;
        font-size: 3em;
        margin-top: -0em;
        color: #C7C8CC;
    }
    
    .discovery {
        font-size: 2em;
        font-weight: 700;
        margin-top: -1em;
        font-family: 'Rubik';
    }
    
    .disLine2 {
        width: 250px;
        margin-top: -1em;
    }

    .fourthLine {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    
.market {
    font-family: 'Rubik';
    font-size: 1.5em;
    border-left: 5px solid orangered;
    padding: 5px 5px;

}

.firstRow4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    
}

.lowInvest {
    width: 350px;
    margin-top: 5em;
}

.vcPe {
    font-weight: 500;
    margin-left: 0em;
    border-left: 2px solid orangered;
    padding: 5px 5px;
}

.genAI {
    font-family: 'rubik';
    font-weight: 700;
    font-size: 1.5em;
    width: 300px;
    margin-left: 0em;
    text-align: left;
    margin-top: -0em;
}

.readNow {
    color: #4C3BCF;
    text-decoration: none;
    font-weight: 500;
    margin-top: -1em;
    margin-left: 0em;

}

.genZ {
    width: 350px;
}

.firstRow5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.TA1 {
    font-family: 'Rubik';
    font-size: 1.5em;
    border-left: 2px solid orangered;
    padding: 5px 5px;
    margin-bottom: -2em;
    margin-left: 0em;
}
.workingRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3em;
    margin-top: 5em;
}

.working {
    width: 350px;
    
}

.workingTitle {
    font-family: 'Rubik';
    font-weight: 700;
    width: 250px;
}

.readNow2 {
    color: #4C3BCF;
    text-decoration: none;
    font-weight: 500;
}

   
    
    
}