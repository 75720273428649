

.menuPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuNames {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 2em;
    color: black;
}

.menuOptions {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.menuList {
    display: flex;
    flex-direction: column;
    margin-left: -15em;
}

.homeBox {
    margin-top: 10em;
    margin-right: -15em;
}

.homeButton {
    width: 30px;
    height: 30px;
}