
.headerPage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'montserrat';
    gap: 45em;
    margin-top: 1em;
    width: auto;
}



.navList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1em;
    font-weight: 100;
    font-size: 1em;
    font-weight: 500;
    font-family: 'Rubik';
    margin-left: 9em;
}

.brandLogo {
    display: flex;
    align-items: left;
    justify-content: left;
}

.brandName {
    width: 200px;
}




.talk {
    background-color: white;
    border-radius: 15px 15px;
    margin-left: 1em;
    padding: 15px 15px;
    color: black;
    font-family: 'Rubik';
    font-weight: 500;
    cursor: pointer;
    outline: none;
}


.arrow {
    font-size: 1em;
    rotate: -45deg;
    margin-left: 2em;
    color: orangered;
}


.menu {
    display: none;
    width: 10px;
  
}

.listNav {
    color: black;
    font-weight: 100;
    cursor: pointer;
}



@media all and (max-width: 430px)  {

    
.headerPage {
    display: flex;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    gap: 0em;
    margin-top: 1em;
}

.navList {
    display: none;
    
}

.brandLogo {
    display: flex;
    align-items: left;
    justify-content: left;
}

.brandName {
    width: 150px;
}

.menu {
    display: flex;
    width: 10px;
    margin-left: 13em;
   
}

.menuColor {
    color: black;
}

}