.aboutPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageFront {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
}

.front11 {
    font-family: 'Montserrat';
    font-size: 5em;
    font-weight: 700;
}

.front22 {
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 300;
    margin-top: -3em;
}

.frontBox11 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5em;
    margin-top: 2em;
}

.frontBox22 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5em;
    margin-top: 1em;
}

.aboutBox11 {
    width: 350px;
    height: 450px;
    border-radius: 15px 15px;
    border: 1px solid black;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-top: 10em;
}

.aboutBox22 {
    width: 350px;
    height: 450px;
    border-radius: 15px 15px;
    border: 1px solid black;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-top: -10em;
}



.oneTitle {
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 700;
    margin-left: 1em;
}


.aboutLine {
    margin-left: 1em;
    margin-top: 15em;
}

.aboutLine2 {
    margin-left: 1em;
    margin-top: 10em;
}

.aboutPara11 {
    font-family: 'Montserrat';
    font-size: 1em;
    margin-left: 1em;
}

.LinkedIn {
    width: 100px;
    height: 30px;
    color: black;
    margin-bottom: -10em;
}

.LinkedIn:hover {
    transition: 1s;
   color: #0077B5;
}


@media all and (max-width: 430px) {

    .aboutPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .pageFront {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
    }
    
    .front11 {
        font-family: 'Montserrat';
        font-size: 5em;
        font-weight: 700;
    }
    
    .front22 {
        font-family: 'Montserrat';
        font-size: 1.3em;
        font-weight: 300;
        margin-top: -3em;
    }
    
    .frontBox11 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 5em;
        margin-top: 0em;
    }
    
    .frontBox22 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 5em;
        margin-top: 1em;
    }
    
    .aboutBox11 {
        width: 350px;
        height: 450px;
        border-radius: 15px 15px;
        border: 1px solid black;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin-top: 3em;
    }
    
    .aboutBox22 {
        width: 350px;
        height: 450px;
        border-radius: 15px 15px;
        border: 1px solid black;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin-top: -0em;
    }
    
    
    
    .oneTitle {
        font-family: 'Montserrat';
        font-size: 1.5em;
        font-weight: 700;
        margin-left: 1em;
    }
    
    
    .aboutLine {
        margin-left: 1em;
        margin-top: 15em;
    }
    
    .aboutLine2 {
        margin-left: 1em;
        margin-top: 10em;
    }
    
    .aboutPara11 {
        font-family: 'Montserrat';
        font-size: 1em;
        margin-left: 1em;
    }
    
    .LinkedIn {
        width: 100px;
        height: 30px;
        color: black;
        margin-bottom: -10em;
    }
    
    .LinkedIn:hover {
        transition: 1s;
       color: #0077B5;
    }
    

}